$join-membership-black=#000000;
$join-membership-hover=#444;

// -------
// New Membership Page
// -------
.membership-container
	max-width 1280px
	margin 0 auto

	p
		margin 0

// Hero Section
.membership-hero
	display flex
	flex-direction column
	background-image url('/img/web/membership/hero_web.png?auto=format')
	background-size cover
	background-position center
	background-repeat no-repeat

	+below(mobile-cutoff)
		background-image url('/img/web/membership/hero_mobile.png?auto=format')

.membership-hero__container
	display flex
	flex-direction column
	align-items center
	justify-content center
	width 100%
	height: 432px
	gap 24px

.membership-hero__container__description
	display flex
	flex-direction column
	width 532px
	gap 8px

	+below(mobile-cutoff)
		width 358px

.membership-hero__container__description__primary
	font-family Concrette
	font-weight 500
	font-size 38px
	color $darkMocha
	line-height 52px
	text-align center

	+below(mobile-cutoff)
		font-size 32px
		line-height 44px

.membership-hero__container__description__auxiliary
	font-family Grotesk
	font-size 20px
	color $darkMocha
	line-height 26px
	text-align center

.membership-hero__container__description__auxiliary__bold
	font-weight 700
	color $forest

.membership-hero__container__start_trial_container
	display flex

.membership-hero__container__start_trial
	font-family Grotesk
	padding 10px 30px
	font-size 14px
	line-height 14px

// Become Member Section
.membership-become-member
	display flex
	flex-direction column
	align-items center
	padding 32px 0
	gap 16px
	width 100%

.membership-become-member-title__container
	display flex
	align-items center
	justify-content center
	width 768px

	+below(ipad-width)
		width 358px

.membership-become-member-title__description
	font-family Concrette
	font-size 32px
	font-weight 500
	color $darkMocha
	line-height 44px
	text-align center

	+below(ipad-width)
		font-size 28px
		line-height 38px

.membership-become-member-benefits
	display flex
	flex-wrap wrap
	gap 54px
	width 784px
	justify-content center

	+below(ipad-width)
		width 358px
		gap 40px

.membership-become-member-benefits__container
	display flex
	gap 64px
	width 784px
	justify-content center

	+below(ipad-width)
		width 358px
		flex-wrap wrap
		gap 40px

.membership-become-member-benefit__container
	display flex
	flex 1 1 calc(50% - 20px)
	align-items center
	flex-direction column
	gap 24px
	box-sizing border-box

	+below(ipad-width)
		flex 1 1 100%

.membership-become-member-benefit__icon-container
	display flex
	position relative
	align-items center
	justify-content center
	width 64px
	height 64px

	img
		position absolute
		top 0

.membership-become-member-benefit__description-container
	display flex
	align-items center
	justify-content center
	flex-direction column
	gap 8px

.membership-become-member-benefit__primary
	font-family Concrette
	font-size 24px
	font-weight 500
	color $darkMocha
	line-height 33px
	text-align center

	+below(ipad-width)
		font-size 24px
		line-height 33px

.membership-become-member-benefit__auxiliary
	font-family Grotesk
	font-size 16px
	color $darkMocha
	line-height 20px
	text-align center

	+below(ipad-width)
		font-size 16px
		line-height 20px

// Flexible Plans Section
.membership-flexible-plans
	display flex
	flex-direction column
	align-items center
	padding 24px 0
	width 100%
	background-color $lightOlive

.membership-flexible-plans-title__container
	display flex
	align-items center

.membership-flexible-plans-title__description
	font-family Concrette
	font-size 32px
	font-weight 500
	color $darkMocha
	line-height 44px
	text-align center

	+below(ipad-width)
		font-size 28px
		line-height 38px

.membership-flexible-plans-options__container
	display flex
	flex-direction row
	align-items center
	margin-top 24px
	gap 24px

	+below(ipad-width)
		flex-direction column

.membership-flexible-plans-options__option
	display flex
	position relative
	flex-direction column
	align-items center
	background-color $white
	width 238px
	height 256px
	border-radius 6px
	box-sizing border-box
	cursor: pointer
	border 2px solid transparent
	outline 1px solid $timberwolf

.membership-flexible-plans-options__option.plan_selected
	outline 3px solid $forest

.membership-flexible-plans-options__selected_option_frame
	display flex
	position absolute
	top -16px
	width 97px
	height 22px
	align-items center
	justify-content center
	border-radius 6px
	border 1px solid $forest
	background $forest

.membership-flexible-plans-options__selected_option_frame_description
	font-family Grotesk
	font-size 12px
	font-weight 700
	color $white
	line-height 14px
	text-align center

.membership-flexible-plans-options__title-container
	display flex
	margin-top 16px
	flex-direction column
	align-items center
	justify-content center
	gap 40px

.membership-flexible-plans-options__title
	font-family Grotesk
	font-size 18px
	color $forest
	line-height 26px
	text-align center

.membership-flexible-plans-options__price-container
	display flex
	flex-direction column
	height 88px
	align-items center
	gap 4px

.membership-flexible-plans-options__price-description
	font-family Concrette
	font-weight 500
	font-size 24px
	color $darkMocha
	line-height 33px
	text-align center

.membership-flexible-plans-options__savings-description
	font-family Grotesk
	font-size 18px
	color $darkMocha
	line-height 26px
	text-align center

.membership-flexible-plans-options__separator
	border-top 1px solid $timberwolf
	margin-top 8px
	width 170px

.membership-flexible-plans-options__billing-frequency-container
	display flex
	height 52px
	width 170px
	justify-content center
	align-items center
	margin-top 13px

.membership-flexible-plans-options__billing-frequency-description
	font-family Grotesk
	font-size 18px
	color $darkMocha
	line-height 26px
	text-align center

.membership-flexible-plans-start-saving__container
	display flex
	margin-top 32px

.membership-flexible-plans-start-saving__button
	width 185px
	height 34px
	font-family Grotesk
	padding 10px 30px
	font-size 14px
	line-height 14px

.membership-flexible-plans-footer__container
	display flex
	flex-direction row
	width 100%
	align-items center
	justify-content center
	margin-top 16px

	+below(ipad-width)
		width 358px

.membership-flexible-plans-footer__description
	font-family Grotesk
	font-weight 700
	font-size 12px
	color $darkMocha
	line-height 15px
	text-align center

// Split Story Section
.membership-split-story
	display flex
	flex-direction row
	align-items center
	justify-content center
	height 328px

	+below(ipad-width)
		flex-direction column
		height 100%

.membership-split-story__image_container
	display flex
	width 40%
	height 100%
	background-image url('/img/web/membership/split_story_web.png?auto=format')
	background-size cover
	background-position center
	background-repeat no-repeat

	+below(ipad-width)
		width 100%
		height 247px
		background-image url('/img/web/membership/split_story_mobile.png?auto=format')

.membership-split-story__content_container
	display flex
	flex-direction column
	justify-content center
	align-items center
	width 60%
	gap 36px

	+below(ipad-width)
		width 100%
		height 100%
		padding 32px 0

.membership-split-story__text_container
	display flex
	flex-direction column
	justify-content center
	align-items center
	width 542px
	max-width 100%
	gap 26px

	+below(ipad-width)
		width 100%

.membership-split-story__primary_container
	display flex
	justify-content center
	align-items center
	max-width 100%
	word-wrap break-word

	+below(ipad-width)
		width 373px

.membership-split-story__primary
	font-family Concrette
	font-size 30px
	color $darkMocha
	line-height 36px
	text-align center

	+below(ipad-width)
		font-size 28px
		line-height 38px


.membership-split-story__auxiliary_container
	display flex
	width 434px
	justify-content center
	align-items center

	+below(ipad-width)
		width 358px


.membership-split-story__auxiliary
	font-family Grotesk
	font-size 16px
	color $darkMocha
	line-height 22px
	text-align center

.membership-split-story__auxiliary__bold
	font-weight 700

.membership-split-story__reorder_button_container
	display flex

.membership-split-story__reorder_button
	width 244px
	height 34px
	font-family Grotesk
	padding 10px 30px
	font-size 14px
	line-height 14px

// -------
// Old Membership Page
// -------
.join-membership__body
	display flex
	flex-wrap wrap-reverse
	flex-direction column-reverse
	align-items center
	gap 32px
	+above(mobile-cutoff)
		flex-direction row
		gap 64px

.membership__row_image
	flex 1
	& img
		width 100%
		object-fit cover
		aspect-ratio 1/1 
		+above(mobile-cutoff)
			aspect-ratio  4/5

.join-membership 
	display flex
	justify-content center
	color $join-membership-black

.join-membership__description
	margin-bottom 16px

.join-membership__content
	font-family $outfitFontFamily
	display flex
	flex-direction column
	width 100%
	max-width 1200px
	padding 32px 16px
	+above(mobile-cutoff)
		padding 32px 40px
	+above(tablet-cutoff)
		padding 32px 140px

.join-membership__title
	title-outfit()
	font-size 4.166rem
	margin 28px 0 16px
	+above(mobile-cutoff)
		margin 36px 0

.small-divider
	border-bottom 1px solid $join-membership-black
	max-width 340px

.join-membership__benefits
	title-outfit()
	flex-basis 60%

.join-membership__benefits_header
	title-outfit();
	font-size 4.166rem
	margin-bottom 40px;

.join-membership__benefit_header
	title-outfit();
	font-size 1.75rem
	margin-bottom 5px;

.join-membership__benefit_description
	body-outfit-3();
	font-size 1.25rem
	margin-top 5px
	margin-bottom 12px
	line-height 29.9px

.join-membership__benefit_description__list
	padding-left: 14px;

.join-membership__join__content
	body-outfit-3()

.join-membership__join
	max-width 650px;
	margin-top 24px

.btn-join-membership
	padding 20px 75px
	color $white
	background $join-membership-black
	letter-spacing 0.25em
	border 0
	cursor pointer
	&:hover
		background $join-membership-hover

.join-membership__error
	margin-top 40px

.join-membership__footer
	body-outfit-copy-small-semibold()
	margin-top 72px
	padding 0 12px

